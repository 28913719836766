@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/highlightjs/styles/github.css";

small {
    display: block;
    color: #888;
}

main.container, footer.container {
    max-width: 900px;
}

footer.container {
    margin-top: 30px;
    padding: 30px 0;
    border-top: 1px dashed #aaa;
}

@media (max-width: 767px) {
    .sidebar {
        padding-top: 30px;
    }
}

@media (min-width: 980px) {
    body {
        padding-bottom: 40px;
    }
}

body > main.container {
    padding: 80px 15px 0; /* 80px to make the container go all the way to the bottom of the topbar */
}

a {
    color: #005cbf;
}
